.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0.2rem;
}
.programs-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-style: italic;
}
@media  screen and (max-width:786px) {
    .programs-header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .programs-categories{
        flex-direction: column;
    }
}